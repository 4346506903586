
var Nodeurl = "";
var originFolder = "";
var pythonUrl = "";
var authURL = "";
var currUrl = "";

if (window.location.href.includes("localhost")) {
    currUrl = "http://localhost:3000";
    originFolder = "C:/Users/Gad/Documents/GitHub/try/Files/";
    Nodeurl = "http://localhost:8090"
    pythonUrl = "http://127.0.0.1:5100";
    authURL = "http://localhost:3001"
}
else 
{
    currUrl = "http://portal.bitsonic.ai";
    originFolder = "/mnt/disks/birddata2/birddata/";
    Nodeurl      = "https://api.bademeister-jan.pro:453"
    pythonUrl    = "https://api.bademeister-jan.pro:336";
    authURL = "http://auth.bitsonic.ai"
}
export {Nodeurl,originFolder,pythonUrl,authURL,currUrl};
export const HTTP_200_OK = 200;
export const ApiCall = async (apicall,parameters,setCookie,raiseError= (err)=>{console.log(err)}) =>
{
    var res = undefined;
    await fetch(
        apicall,
        parameters
    )
    .then((data) =>
    {
        if (data.status == 401)
        {
            console.log(authURL);   
            setCookie("redirection",currUrl,{"domain" : currUrl.includes("bitsonic") ? ".bitsonic.ai" : "localhost"});
            alert("re-login required");
            window.location.href = authURL; 
        }
        else
        {
            const dataHeaders = Object.fromEntries(data.headers);
            if(dataHeaders.jwt != undefined)
                setCookie("jwt",dataHeaders.jwt,{"domain" : currUrl.includes("bitsonic") ? ".bitsonic.ai" : "localhost"});
            res = data;
        }
    }).catch((err) => raiseError(err));
    return res;
}
